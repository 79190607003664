<template>
  <a-modal
    :visible="visible"
    :wrap-class-name="$style.modalSync"
    :closable="false"
    :width="showSuccessIntegrate ? 400 : 800"
  >
    <template v-if="showSuccessIntegrate">
      <img :src="require(`@/assets/store/add-store/success-add.svg`)" style="width:60px !important; height:60px !important;" />
      <h4 class="mt-4">
        Berhasil!
      </h4>
      <div class="mt-4 w-100 text-center">
        Proses Integrasi Toko Tokopedia anda sedang dalam proses, silahkan cek email anda.
      </div>
    </template>
    <template v-else>
      <img :src="require(`@/assets/store/add-store/tokopedia.svg`)" />
      <div class="mt-2 pbz-font body-sm-medium form-disclaimer">
        Form ini merupakan formulir untuk pengajuan proses integrasi tokopedia
      </div>
      <a-form-model
        id="form-integrate"
        ref="ruleForm"
        :model="form"
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
        class="w-100"
        @submit.prevent="handleSubmitForm(detailData?.id, detailData?.name)"
      >
        <div class="mt-2 w-100">
          <template v-if="form.is_binding === 0">
            <a-form-model-item
              class="mt-4"
              prop="is_binding"
              label="Apakah tokopedia anda sudah melakukan binding dengan tiktok shop?"
              :rules="[{ required: true, message: 'Harap pilih status binding' }]"
            >
              <a-radio-group
                v-model="form.is_binding"
              >
                <a-radio style="display: flex; height: 30px; line-height: 30px; align-items: center;" :value="1">
                  Belum binding
                </a-radio>
                <a-radio style="display: flex; height: 30px; line-height: 30px; align-items: center;" :value="2">
                  Ya, Sudah binding
                </a-radio>
              </a-radio-group>
            </a-form-model-item>
          </template>
          <template v-if="form.is_binding === 1">
            <div class="d-flex align-items-center mt-4 mb-4">
              <a-icon type="arrow-left" class="mr-2" style="font-size: 16px; color: black; cursor: pointer;" @click="form.is_binding = 0" />
              <h5 class="text-left mb-0">
                Lengkapi detail informasi berikut
              </h5>
            </div>
            <a-form-model-item
              prop="store_name"
              label="Nama Toko"
              :rules="[{ required: form.is_binding === 2, message: 'Harap masukan nama toko!' }]"
            >
              <a-input
                v-model="form.store_name"
                class="w-100"
                style="width:100%;"
                placeholder="Masukan nama toko"
                :required="form.is_binding === 2"
              />
            </a-form-model-item>
            <a-form-model-item
              prop="store_url"
              label="Domain Toko"
              :rules="[{ required: form.is_binding === 2, message: 'Harap masukan domain toko!' }]"
            >
              <a-input
                v-model="form.store_url"
                class="w-100"
                addon-before="https://www.tokopedia.com/"
                placeholder=". . ."
                :required="form.is_binding === 2"
                @keypress="onChangeStoreUrl"
                @blur="onBlurStoreUrl"
              />
            </a-form-model-item>
          </template>
          <template v-if="form.is_binding === 2">
            <div class="d-flex justify-content-center flex-column align-items-center mt-4" style="width: 100%;">
              <img
                v-if="detailDataTokopediaBinding?.logo"
                :src="require(`@/assets/store/add-store/${detailDataTokopediaBinding?.logo}`)"
              />
              <h4 class="mt-5">
                {{ $t('add_store.sync_tips') }}
              </h4>
              <p class="mt-2 text-center">
                {{ $t('add_store.note_tips', { name: detailDataTokopediaBinding?.name }) }}
              </p>
            </div>
          </template>
          <template v-if="form.is_binding === 1">
            <h6 class="text-left w-100 mt-2" style="font-size: 12px">
              Ikuti langkah berikut ini untuk melakukan Integrasi Tokopedia :
            </h6>
            <ol class="pl-3 text-left">
              <li v-for="(item, index) in stepIntegrationTokopedia" :key="index" class="text-desc" style="text-align: justify; color: #6C757D; font-size: 12px">
                {{ item }}
              </li>
            </ol>
          </template>
        </div>
      </a-form-model>
    </template>
    <template #footer>
      <template v-if="form.is_binding === 1">
        <template v-if="showSuccessIntegrate">
          <a-button
            class="mr-2"
            @click.prevent="showSuccessIntegrate = false"
          >
            Tambah Toko Lainnya
          </a-button>
          <ConsoleLink :to="{ name: 'store.list' }">
            <a-button
              type="primary"
              :loading="integration.loadingSync"
            >
              Selesai
            </a-button>
          </ConsoleLink>
        </template>
        <template v-else>
          <a-button
            class="mr-2"
            @click="form.is_binding = 0"
          >
            Kembali
          </a-button>
          <a-button
            type="primary"
            html-type="submit"
            form="form-integrate"
            :loading="integration.loadingSync"
          >
            Request Integrasi
          </a-button>
        </template>
      </template>
      <template v-else-if="form.is_binding === 2">
        <a-button
          class="mr-2"
          @click="form.is_binding = 0"
        >
          Kembali
        </a-button>
        <a-button
          type="primary"
          :loading="integration.loadingSync"
          @click="syncMarketPlace(detailDataTokopediaBinding?.id, detailDataTokopediaBinding?.name, detailDataTokopediaBinding?.channelCode)"
        >
          {{ $t('add_store.start_sync') }}
        </a-button>
      </template>
      <template v-else>
        <a-button
          class="mr-2"
          @click="typeof back !== 'undefined' && back(), openModal(null)"
        >
          Tutup
        </a-button>
      </template>
    </template>
  </a-modal>
</template>

<script>
import data from '@/services/store/data'
import ConsoleLink from '@/components/ConsoleLink/ConsoleLink.vue'

const DEFAULT_TIKTOK_TOKOPEDIA = {
    id: 93,
    name: 'Tiktok Tokopedia',
    type: 'store',
    logo: 'tiktok-shop-tokopedia.webp',
    status: '',
    channelCode: 'tiktok_tokopedia',
    redirectIntegrate: true,
}
export default {
  name: 'ModalSyncWithForm',
  components: {
    ConsoleLink,
  },
  props: {
    visible: Boolean,
    back: Function,
    openModal: Function,
    openModalSuccess: Function,
    idData: Number,
  },
  data: function () {
    return {
      form: {
        store_name: '',
        store_url: '',
        is_binding: 0,
      },
      showSuccessIntegrate: false,
      stepIntegrationTokopedia: [
        'Lakukan Permintaan integrasi toko dengan menginput Nama dan Domain Toko Anda. Toko Anda akan berstatus “Menunggu” (Menunggu konfirmasi dari Penjual). Nama dan domain toko Anda dapat dilihat pada web seller center Tokopedia > Pengaturan Toko > Informasi. Lalu copy informasi toko pada bagian Domain Toko (cukup salin tulisan yang setelah "www.tokopedia.com/")',
        'Tokopedia akan mengirimkan email konfirmasi persetujutan integrasi toko ke alamat email toko Anda. Anda juga dapat menerima persetujuan integrasi toko melalui web seller center Tokopedia > Pengaturan Aplikasi Pihak Ketiga > Menunggu persetujuan.',
        'Lalu tunggu proses verifikasi data oleh Powerbiz selama ± 1x24 jam. Proses verifikasi selesai, ditandai dengan status toko Anda berubah menjadi “Pending Warehouse” pada Daftar Toko. Powerbiz juga akan mengirimkan notifikasi ke alamat email Anda, menginformasikan bahwa proses verifikasi sudah selesai.',
        'Lakukan pemetaan gudang yang Anda miliki di Powerbiz dengan gudang Tokopedia melalui menu daftar toko.',
        'Toko Anda siap dioperasikan melalui Powerbiz.',
      ],
      optionsBinding: [
        { label: 'Belum binding', value: false },
        { label: 'Ya, Sudah binding', value: true },
      ],
      detailDataTokopediaBinding: DEFAULT_TIKTOK_TOKOPEDIA,
    }
  },
  computed: {
    detailData() {
      const finalData = [...data, DEFAULT_TIKTOK_TOKOPEDIA]
      return finalData.find((val) => val.id === this.idData)
    },
    integration() {
      return this.$store.state.integration
    },
  },
  watch: {
    visible: {
      handler(value) {
        if (value) {
          this.form = {
            store_name: '',
            store_url: '',
            is_binding: 0,
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    async syncMarketPlace(id, name, channelCode) {
      if (id !== null) {
        let route = {
          path: this.$route.path,
          query: this.$route.query,
        }
        if (channelCode === 'tiktok_tokopedia') {
          route = {
            ...route,
            query: {
              ...route.query,
              'company-aggregator-id': id,
              name: name.toLowerCase(),
            },
          }
          localStorage.setItem('pathCallback', JSON.stringify(route))
          localStorage.setItem('channelId', null)
          localStorage.setItem('needBinding', true)
          const { data } = await this.$store.dispatch('integration/SYNCTIKTOK', id)
          window.location = data.data.authorized_url
        }
      } else {
        this.$notification.error({
          message: 'Store id tidak ditemukan',
        })
      }
    },
    onChangeStoreUrl(event) {
      const keyCode = (event.which) ? event.which : event.keyCode
      if(keyCode == 47 || keyCode == 63) {
        event.preventDefault()
      }
    },
    onBlurStoreUrl(event) {
      let value = event.target.value
      const replacedValue = value.replace('https://www.tokopedia.com/', '')
      this.form.store_url = replacedValue.includes('?')
        ? replacedValue.split('?')[0]
        : value.includes('/')
        ? value.replaceAll('/', '')
        : replacedValue
    },
    onClickSubmit() {
      this.$refs['form-integrate'].submit()
    },
    handleSubmitForm(id, name) {
      if (id !== null) {
        if (name === 'Tokopedia') {
          this.$refs.ruleForm.validate(valid => {
            if (!valid) return
            const payload = {
              channelCode: this.detailData?.channelCode,
              store_type: 'OFFICIAL_STORE',
              business: this.$route.query.business_id,
              ...this.form,
              store_url: `https://www.tokopedia.com/${this.form.store_url}`,
            }
            this.$store.dispatch('integration/INTEGRATIONSALESCHANNEL', payload)
              .then((data) => {
                if (data?.channelId && data?.status?.toUpperCase() === 'WAITING') {
                  this.showSuccessIntegrate = true
                }
              })
              .catch((err) => {
                this.$notification.error({
                  message: 'Connected Failed',
                  description: err.response?.data?.message || err.message,
                })
              })
          })
        }
      }
    },
  },
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>
<style lang="scss" scoped>
.form-disclaimer {
  color: var(--kit-color-primary);
  font-style: italic !important;
}
</style>
